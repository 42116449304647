<template>
	<div
		v-if="computedSpotBoxes && computedSpotBoxes.length"
		class="c-block-list-spot-box-custom"
	>
		<LinkTile
			v-for="(spotBox, index) in computedSpotBoxes"
			:key="spotBox.key"
			:class="[
				'c-block-list-spot-box-custom__tile',
				'grid grid-cols-2 gap-x-layout-gutter',
				'group w-full flex-shrink-0',

				{
					'c-block-list-spot-box-custom__tile--multiple >=768:w-6/12col':
						computedSpotBoxes.length >= 2,
					'>=1024:w-4/12col': computedSpotBoxes.length === 3,
				},
			]"
			:aria-labelledby="`spot-box-title-${_uid}-${index}`"
			link-partials-query=".c-block-list-spot-box-custom__link-partial"
			:to="spotBox.link.url"
			:target="spotBox.link.target"
		>
			<!-- Label -->
			<small
				v-if="spotBox.label"
				class="
					c-block-list-spot-box-custom__link-partial
					col-span-2
					pb-md
				"
				:class="[
					'font-darker-grotesque font-bold text-label-spotbox uppercase',
					{
						'>=768:col-span-1 >=768:col-start-2 >=768:row-start-2 >=768:pb-md':
							computedSpotBoxes.length === 1,
					},
				]"
				v-html="spotBox.label"
			></small>

			<!-- Image -->
			<figure
				class="flex justify-center items-center col-start-1 col-span-2"
				:class="{
					'>=768:col-span-1 >=768:pr-1/12col >=768:row-start-1 >=768:row-span-4':
						computedSpotBoxes.length === 1,
				}"
			>
				<div
					class="
						c-block-list-spot-box-custom__link-partial
						c-block-list-spot-box-custom__image-wrapper
					"
					:class="[
						`c-block-list-spot-box-custom__image-wrapper--count--${computedSpotBoxes.length}`,
						'relative overflow-hidden',
					]"
				>
					<UmbracoImageExt
						v-if="spotBox.image"
						class="absolute left-0 top-0 w-full h-full"
						:class="[
							'duration-500 ease-smooth-out',
							'transform group-data-hover:scale-105',
						]"
						:widths="[343, 416, 560, 640, 800, 1000, 1280]"
						:alt="spotBox.image.altText"
						:source-url="spotBox.image.cropUrl"
						:source-width="spotBox.image.width"
						:source-height="spotBox.image.height"
						:aspect-ratio="1"
					/>
					<FallbackImage
						v-else
						class="absolute left-0 top-0 w-full h-full"
					/>
				</div>
			</figure>

			<!-- Content -->
			<div
				class="col-span-2"
				:class="{
					'>=768:col-span-1 >=768:col-start-2 >=768:row-start-3':
						computedSpotBoxes.length === 1,
				}"
			>
				<div
					class="c-block-list-spot-box-custom__link-partial pt-xl"
					:class="{
						'>=768:pt-0': computedSpotBoxes.length === 1,
					}"
				>
					<!-- Title -->
					<h2
						v-if="computedSpotBoxes.length === 1"
						:class="[
							'hidden >=768:block',
							'font-darker-grotesque text-title-spotbox font-medium',
						]"
						v-html="spotBox.title"
					></h2>
					<div
						:class="[
							'duration-500 ease-smooth-out',
							'transform group-data-hover:translate-x-8',
							{
								'>=768:hidden': computedSpotBoxes.length === 1,
							},
						]"
					>
						<div
							:class="[
								'inline-flex items-center justify-center',
								'w-lg h-lg -my-lg rounded-full bg-black text-white',
								'duration-500 ease-smooth-out',
								'transform group-data-hover:-translate-x-8',
							]"
							style="margin-right: 0.33em"
						>
							<SvgIconArrow style="width: 50%" />
						</div>

						<h2
							:id="`spot-box-title-${_uid}-${index}`"
							class="
								inline
								font-semibold font-darker-grotesque
								text-title-spotbox-sm
							"
							v-text="spotBox.title"
						></h2>
					</div>

					<!-- Teaser -->
					<p
						v-if="spotBox.teaser"
						:aria-label="
							spotBox.teaser !== spotBox.originalTeaser
								? spotBox.originalTeaser
								: null
						"
						class="block mt-md s-rich-text"
					>
						{{ spotBox.teaser }}
						<span
							v-if="spotBox.teaser !== spotBox.originalTeaser"
							aria-hidden="true"
							>...</span
						>
					</p>
				</div>

				<!-- Link button -->
				<div
					v-if="computedSpotBoxes.length === 1 && spotBox.link.name"
					class="
						c-block-list-spot-box-custom__link-partial
						hidden
						>=768:inline-flex
						items-center
						space-x-12
						pt-2xl
					"
				>
					<div
						:class="[
							'flex items-center justify-center',
							'w-lg h-lg rounded-full bg-black text-white',
						]"
					>
						<SvgIconArrow style="width: 50%" />
					</div>

					<span
						class="
							font-bold font-darker-grotesque
							uppercase
							text-button-xs
							duration-500
							ease-smooth-out
							transform
							group-data-hover:translate-x-8
						"
						v-text="spotBox.link.name"
					></span>
				</div>
			</div>
		</LinkTile>
	</div>
</template>

<script>
import LinkTile from '~/citi-baseline/components/LinkTile';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import FallbackImage from '~/components/shared/FallbackImage';
import SvgIconArrow from '~/assets/svgs/icon-arrow.svg?inline';

export default {
	name: 'BlockListSpotBoxCustom',

	components: { LinkTile, UmbracoImageExt, FallbackImage, SvgIconArrow },
	inheritAttrs: false,

	props: {
		spotBoxes: {
			type: Array,
			default: () => [],
		},
	},

	computed: {
		computedSpotBoxes() {
			return (
				this.spotBoxes
					?.map?.((original) => {
						let composite = original?.content;
						if (composite) {
							composite = JSON.parse(JSON.stringify(composite));
							composite.originalTeaser = composite.teaser.trim();
							composite.teaser = this.limitTeaser(
								composite.teaser.trim()
							);
							composite.link = composite.link?.[0];
						}

						return composite;
					})
					.filter(Boolean)
					.filter((composite) => composite.link) ?? []
			);
		},
	},

	methods: {
		limitTeaser(teaser, limit = 200, buffer = 3) {
			if (teaser.length > limit + buffer) {
				teaser = teaser
					.substring(0, limit)
					.split(' ')
					.filter(Boolean)
					.join(' ');
			}
			return teaser;
		},
	},
};
</script>

<style lang="postcss">
.c-block-list-spot-box-custom {
	@apply flex flex-wrap >=1024:flex-nowrap gap-x-layout-gutter gap-y-4xl;
}

.c-block-list-spot-box-custom__image-wrapper {
	@apply w-full h-0;
	padding-top: min(100%, 640px);

	& img {
		object-fit: cover;
	}
}

.c-block-list-spot-box-custom__image-wrapper--count--1 {
	padding-top: 100%;
}

.c-block-list-spot-box-custom__tile--multiple {
	grid-template-rows: auto auto 1fr;
}
</style>
