var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.computedSpotBoxes && _vm.computedSpotBoxes.length)?_c('div',{staticClass:"c-block-list-spot-box-custom"},_vm._l((_vm.computedSpotBoxes),function(spotBox,index){return _c('LinkTile',{key:spotBox.key,class:[
			'c-block-list-spot-box-custom__tile',
			'grid grid-cols-2 gap-x-layout-gutter',
			'group w-full flex-shrink-0',

			{
				'c-block-list-spot-box-custom__tile--multiple >=768:w-6/12col':
					_vm.computedSpotBoxes.length >= 2,
				'>=1024:w-4/12col': _vm.computedSpotBoxes.length === 3,
			},
		],attrs:{"aria-labelledby":`spot-box-title-${_vm._uid}-${index}`,"link-partials-query":".c-block-list-spot-box-custom__link-partial","to":spotBox.link.url,"target":spotBox.link.target}},[(spotBox.label)?_c('small',{staticClass:"c-block-list-spot-box-custom__link-partial col-span-2 pb-md",class:[
				'font-darker-grotesque font-bold text-label-spotbox uppercase',
				{
					'>=768:col-span-1 >=768:col-start-2 >=768:row-start-2 >=768:pb-md':
						_vm.computedSpotBoxes.length === 1,
				},
			],domProps:{"innerHTML":_vm._s(spotBox.label)}}):_vm._e(),_vm._v(" "),_c('figure',{staticClass:"flex justify-center items-center col-start-1 col-span-2",class:{
				'>=768:col-span-1 >=768:pr-1/12col >=768:row-start-1 >=768:row-span-4':
					_vm.computedSpotBoxes.length === 1,
			}},[_c('div',{staticClass:"c-block-list-spot-box-custom__link-partial c-block-list-spot-box-custom__image-wrapper",class:[
					`c-block-list-spot-box-custom__image-wrapper--count--${_vm.computedSpotBoxes.length}`,
					'relative overflow-hidden',
				]},[(spotBox.image)?_c('UmbracoImageExt',{staticClass:"absolute left-0 top-0 w-full h-full",class:[
						'duration-500 ease-smooth-out',
						'transform group-data-hover:scale-105',
					],attrs:{"widths":[343, 416, 560, 640, 800, 1000, 1280],"alt":spotBox.image.altText,"source-url":spotBox.image.cropUrl,"source-width":spotBox.image.width,"source-height":spotBox.image.height,"aspect-ratio":1}}):_c('FallbackImage',{staticClass:"absolute left-0 top-0 w-full h-full"})],1)]),_vm._v(" "),_c('div',{staticClass:"col-span-2",class:{
				'>=768:col-span-1 >=768:col-start-2 >=768:row-start-3':
					_vm.computedSpotBoxes.length === 1,
			}},[_c('div',{staticClass:"c-block-list-spot-box-custom__link-partial pt-xl",class:{
					'>=768:pt-0': _vm.computedSpotBoxes.length === 1,
				}},[(_vm.computedSpotBoxes.length === 1)?_c('h2',{class:[
						'hidden >=768:block',
						'font-darker-grotesque text-title-spotbox font-medium',
					],domProps:{"innerHTML":_vm._s(spotBox.title)}}):_vm._e(),_vm._v(" "),_c('div',{class:[
						'duration-500 ease-smooth-out',
						'transform group-data-hover:translate-x-8',
						{
							'>=768:hidden': _vm.computedSpotBoxes.length === 1,
						},
					]},[_c('div',{class:[
							'inline-flex items-center justify-center',
							'w-lg h-lg -my-lg rounded-full bg-black text-white',
							'duration-500 ease-smooth-out',
							'transform group-data-hover:-translate-x-8',
						],staticStyle:{"margin-right":"0.33em"}},[_c('SvgIconArrow',{staticStyle:{"width":"50%"}})],1),_vm._v(" "),_c('h2',{staticClass:"inline font-semibold font-darker-grotesque text-title-spotbox-sm",attrs:{"id":`spot-box-title-${_vm._uid}-${index}`},domProps:{"textContent":_vm._s(spotBox.title)}})]),_vm._v(" "),(spotBox.teaser)?_c('p',{staticClass:"block mt-md s-rich-text",attrs:{"aria-label":spotBox.teaser !== spotBox.originalTeaser
							? spotBox.originalTeaser
							: null}},[_vm._v("\n\t\t\t\t\t"+_vm._s(spotBox.teaser)+"\n\t\t\t\t\t"),(spotBox.teaser !== spotBox.originalTeaser)?_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("...")]):_vm._e()]):_vm._e()]),_vm._v(" "),(_vm.computedSpotBoxes.length === 1 && spotBox.link.name)?_c('div',{staticClass:"c-block-list-spot-box-custom__link-partial hidden >=768:inline-flex items-center space-x-12 pt-2xl"},[_c('div',{class:[
						'flex items-center justify-center',
						'w-lg h-lg rounded-full bg-black text-white',
					]},[_c('SvgIconArrow',{staticStyle:{"width":"50%"}})],1),_vm._v(" "),_c('span',{staticClass:"font-bold font-darker-grotesque uppercase text-button-xs duration-500 ease-smooth-out transform group-data-hover:translate-x-8",domProps:{"textContent":_vm._s(spotBox.link.name)}})]):_vm._e()])])}),1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }