<template>
	<LensFlare
		:id="$attrs.id || `fallback-image-${_uid}`"
		class="c-fallback-image"
		:style="aspectRatio && `--aspect-ratio: ${aspectRatio}`"
		v-bind="variantBindings"
	/>
</template>

<script>
import LensFlare from '~/components/shared/LensFlare';

const variants = [
	// Default / variant 0
	{
		angle: 30,
		'min-blur-amount': 40,
		'max-blur-amount': 40,
		flares: [
			{
				size: 1400,
				offset: -500,
				theme: 'primary',
				opacity: 1,
			},
			{
				size: 1200,
				offset: 500,
				theme: 'secondary',
				opacity: 0.75,
			},
		],
	},
	// News / variant 1
	{
		angle: -45,
		flares: [
			{
				size: 1800,
				offset: -400,
				theme: 'primary',
				opacity: 1,
			},
			{
				size: 1200,
				offset: 500,
				theme: 'secondary',
				opacity: 0.8,
			},
		],
	},
	// Spotbox / variant 2
	{
		angle: -160,
		flares: [
			{
				size: 1400,
				offset: -400,
				theme: 'primary',
				opacity: 1,
			},
			{
				size: 900,
				offset: 300,
				theme: 'secondary',
				opacity: 0.75,
			},
		],
	},
	// Person / variant 3
	{
		angle: 140,
		flares: [
			{
				size: 1200,
				offset: 0,
				theme: 'primary',
				opacity: 1,
			},
			{
				size: 600,
				offset: -800,
				theme: 'secondary',
				opacity: 0.5,
			},
			{
				size: 1600,
				offset: 900,
				theme: 'secondary',
				opacity: 0.8,
			},
		],
	},
];

export default {
	name: 'FallbackImage',

	components: {
		LensFlare,
	},

	props: {
		aspectRatio: {
			type: [Number, String],
			default: 0,
		},
		variant: {
			type: Number,
			default: 0,
		},
	},

	computed: {
		variantBindings() {
			return variants[this.variant]
				? { ...variants[0], ...variants[this.variant] }
				: variants[0];
		},
	},
};
</script>

<style>
.c-fallback-image {
	width: 100%;
	aspect-ratio: var(--aspect-ratio, auto);
	background-color: white;
	overflow: hidden;
}
</style>
